import { observer } from "mobx-react";
import React from "react";
import { AssetExaminer } from "../../components/game/AssetExaminer";
import GameLayout from "../../components/game/GameLayout";
import { useCards } from "../../hooks/useCards";
import { useLoadGameData } from "../../hooks/useLoadGameData";
import { L } from "../../l10n/L10n";
import Styles from "./cards.module.scss";

const MyCardsPage = observer(() => {
	const allData = useCards({useAll: true});
	useLoadGameData();

	return (
		<GameLayout title={L.gameCards.title} description={L.gameCards.desc}>
			<div className={Styles.stylePageContainer}>
				<AssetExaminer
					assetsToShow={allData}
					mode={"ALL"}
				/>
			</div>
		</GameLayout>
	);
});

export default MyCardsPage;
